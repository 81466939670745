<script setup lang="ts">
import type { SearchResponse } from '~/models/Content/Response'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import { computed, watch, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { KsPagination } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import { ContentType } from '~/models/Content/ContentType'
import { ColorName } from '~/models/Content/Color'
import useSearchParams from '~/composables/useSearchParams'
import useSearchHelper from '~/composables/useSearchHelper'
import useSearchColors from '~/composables/useSearchColors'
import useSearchClient from '~/composables/useSearchClient'
import useContentMapper from '~/composables/useContentMapper'
import { useAppColor } from '~/composables/useAppColor'
import SearchInput from '~/components/search/SearchInput.vue'
import SearchActiveFilters from '~/components/search/SearchActiveFilters.vue'
import NoSearchResults from '~/components/search/NoSearchResults.vue'
import ProductSearchResults from '~/components/products/ProductSearchResults.vue'
import ProductSearchRelevantCards from '~/components/products/ProductSearchRelevantCards.vue'
import ProductSearchFilters from '~/components/products/ProductSearchFilters.vue'

const props = defineProps<{ product: ContentProduct }>()

const { t } = useI18n()
const router = useRouter()
const { mapResult, mapContents } = useContentMapper()
const { searchPath, cardFields, defaultContentTypes } = useSearchHelper()
const { colorVariables } = useSearchColors()

const {
  searchQuery,
  page,
  queryParams,
  gradeCriterion,
  languageCriterion,
  themeCriterion,
  seriesCriterion,
  genreCriterion,
  checkboxCriterion,
  parentLocationIdCriterion,
  hasCriterions,
  hasCriterionsOrQuery,
  hasQuery,
  sort,
  setSortField,
  toggleCriterion,
  resetCriterions,
  resetSearch,
} = useSearchParams()

const { fetchResults, results: response } = useSearchClient<SearchResponse>(
  searchPath.value,
  { transformData: (response: SearchResponse) => response }
)

useAppColor(ColorName.Purple5)

const FUZZINESS = 1.0
const PAGE_LIMIT = 24
const AGGREGATION_FIELDS = ['grades', 'languages', 'themes', 'series', 'genres', 'has_audio', 'has_more']
const BOOST_FIELDS = { 'search_keywords': 20, 'title': 10, 'm_intro': 4, 'intro': 2 }

const results = computed(() => data.value ? mapContents(data.value) : [])
const totalCount = computed(() => data.value?.count || 0)
const totalPages = computed(() => Math.ceil(totalCount.value / PAGE_LIMIT))
const isLoading = computed(() => isLoadingResults.value || isFetching.value)
const offset = computed(() => (page.value - 1) * PAGE_LIMIT)
const queryKey = computed(() => ['product-search', props.product?.pathString, searchQuery.value, page.value, criterions.value, sort.value, parentLocationIdCriterion.value])
const showRelevantCards = computed(() => page.value === 1 && !hasCriterionsOrQuery.value && !parentLocationIdCriterion.value.length && !gradeCriterion.value.length)
const subtreeCriterion = computed(() => [props.product?.pathString])

const spellingIncorrect = computed(() => !!response.value?.Spellcheck?.Incorrect)
const spellingSuggestion = computed(() => response.value?.Spellcheck?.Suggestion ?? '')


const criterions = computed(() => ({
  language: languageCriterion.value,
  theme: themeCriterion.value,
  series: seriesCriterion.value,
  genre: genreCriterion.value,
  checkbox: checkboxCriterion.value,
  grade: gradeCriterion.value,
}))

const contentTypeMap: Record<string, ContentType[]> = {
  '9788203410833': [ContentType.BookReader],
  // More mappings here
}

const contentTypeCriterion = computed(() => {
  if (parentLocationIdCriterion.value.length) return defaultContentTypes
  return contentTypeMap[props.product?.ean] || defaultContentTypes
})

const doProductSearch = async ({ signal }: { signal?: AbortSignal }) => {
  return await fetchResults({
    text: searchQuery.value,
    fuzziness: FUZZINESS,
    spellcheck: true,
    fields: cardFields,
    boost: BOOST_FIELDS,
  }, {
    contentTypeCriterion: contentTypeCriterion.value,
    subtreeCriterion: subtreeCriterion.value,
    languageFieldCriterion: languageCriterion.value,
    gradeFieldCriterion: gradeCriterion.value,
    seriesFieldCriterion: seriesCriterion.value,
    themeFieldCriterion: themeCriterion.value,
    genreFieldCriterion: genreCriterion.value,
    checkboxFieldCriterion: checkboxCriterion.value,
    parentLocationIdCriterion: parentLocationIdCriterion.value,
    sortField: sort.value.field,
    sortOrder: sort.value.order,
    gradeOperator: 'or',
    aggregationCriterion: AGGREGATION_FIELDS,
    mainLocationCriterion: !parentLocationIdCriterion.value.length,
  }, PAGE_LIMIT, offset.value, signal)}

const {
  data,
  isError,
  isLoading: isLoadingResults,
  isFetching,
} = useQuery({
  queryKey,
  queryFn: doProductSearch,
  select: (data) => mapResult(data),
  staleTime: Infinity,
  enabled: computed(() => !!props.product?.pathString),
  placeholderData: (previousData) => previousData,
})

const replaceRoute = () =>
  router.replace({ name: 'product', query: queryParams.value })

const updateSearchQuery = (newQuery: string) => {
  searchQuery.value = newQuery
  page.value = 1
}

const pushPage = (newPage: number) => {
  page.value = newPage
  scrollTo(0, 0)
}

watchEffect(() => setTitle(props.product?.title || ''))
watch(queryKey, () => !parentLocationIdCriterion.value.length && replaceRoute(), { deep: true })
</script>

<template>
  <div
    :style="colorVariables"
    class="mx-auto mb-12 mt-28 max-w-screen-au grid-cols-1 grid-rows-[auto,auto,auto,1fr,auto] gap-x-8 gap-y-4 px-4 grid-areas-search-view-mobile sm:px-8 md:grid-cols-3 md:gap-x-12 md:grid-areas-search-view-tablet"
    :class="{'grid': !parentLocationIdCriterion.length}"
  >
    <SearchInput
      v-if="!parentLocationIdCriterion.length"
      :title="product.title"
      :placeholder="t(`search.product.placeholder.${product.title}`)"
      :query-key="queryKey"
      :search-query="searchQuery"
      @update-search-query="updateSearchQuery"
    />
    <ProductSearchFilters
      v-if="!parentLocationIdCriterion.length"
      :results="data ?? {}"
      :is-loading="isLoadingResults"
      :is-refetching="isLoading"
      :criterions="criterions"
      @toggle-criterion="toggleCriterion"
    />
    <SearchActiveFilters
      v-if="!parentLocationIdCriterion.length"
      :criterions="criterions"
      :is-loading="isLoading"
      :page="page"
      :total-pages="totalPages"
      :total-count="totalCount"
      :field="searchQuery.length ? sort.field : null"
      :group-grades="true"
      @remove-criterion="toggleCriterion"
      @reset-criterions="resetCriterions"
      @set-sort-field="setSortField"
    />
    <ProductSearchRelevantCards
      v-if="showRelevantCards"
      :product-pathstring="product.pathString"
    />
    <div class="grid-in-results">
      <ProductSearchResults
        :results="results"
        :location-id="parentLocationIdCriterion"
        :is-loading="isLoading"
        :product-title="product.title"
      />
      <NoSearchResults
        :no-results="!results.length"
        :has-query="hasQuery"
        :has-failed="isError"
        :is-loading="isLoading"
        :has-criterions="hasCriterions"
        :spelling-suggestion="spellingIncorrect ? spellingSuggestion : ''"
        @reset-criterions="resetCriterions"
        @reset-search="resetSearch"
        @suggested-search="updateSearchQuery(spellingSuggestion)"
      />
    </div>
    <KsPagination
      v-if="totalCount > PAGE_LIMIT"
      :current-page="page"
      :items-per-page="PAGE_LIMIT"
      :item-count="totalCount"
      class="ml-auto mt-12 grid-in-pagination"
      :disabled="isLoading"
      @push-page="pushPage"
    />
  </div>
</template>
