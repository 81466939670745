<script setup lang="ts">
import type { TeacherArticle } from '~/models/TeacherArticle'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useSearchHelper from '~/composables/useSearchHelper'
import useSearchClient from '~/composables/useSearchClient'
import useContentMapper from '~/composables/useContentMapper'
import { useContentHelper } from '~/composables/useContentHelper'

const props = defineProps<{
  selectedHeader: ContentProductHeader | undefined
}>()

const { mapContent, mapLocations } = useContentMapper()
const { emptyQuery, getContentIdCriterion, getContentTypeCriterion, searchPath } = useSearchHelper()
const { buildResourceUrl } = useUrlBuilder()

const { t } = useI18n()
const router = useRouter()
const { getTitle } = useContentHelper()
const {
  results,
  fetchResults,
} = useSearchClient<TeacherArticle[]>(searchPath.value, {
  transformData: (response) => mapLocations(response.View.Result).map(mapContent)
})

onMounted(async () => {
  if (!props.selectedHeader) return
  let teacherGuideContentId = props.selectedHeader.teacherGuide.destinationContentId
  if (typeof teacherGuideContentId !== 'number') teacherGuideContentId = parseInt(teacherGuideContentId)
  if (!teacherGuideContentId) return

  await fetchResults(emptyQuery, {
    ...getContentIdCriterion([teacherGuideContentId]),
    ...getContentTypeCriterion([ContentType.TeacherArticle]),
    })
})

const teacherArticleLink = computed(() => {
  if (!results.value || !results.value.length) return ''
  const teacherArticle = results.value[0]
  return buildResourceUrl(teacherArticle)
})
</script>
<template>
  <KsButton
    v-if="selectedHeader && teacherArticleLink"
    variant="border"
    size="medium"
    shape="rounded"
    @click.prevent="router.push(teacherArticleLink)"
  >
    {{ t('header.teacherGuide.teacherGuide') }}
  </KsButton>
</template>
