<script setup lang="ts">
import type { Component } from 'vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { setTitle } from '~/utils/dom'
import { useAuthStore } from '~/stores/auth'
import { GradeType } from '~/models/GradeType'
import { ColorName } from '~/models/Content/Color'
import { useAppColor } from '~/composables/useAppColor'
import UpperSecondaryHome from '~/components/home/UpperSecondaryHome.vue'
import PrepHome from '~/components/home/PrepHome.vue'
import LowerPrimaryHome from '~/components/home/LowerPrimaryHome.vue'
import DefaultHome from '~/components/home/DefaultHome.vue'
import AdultHome from '~/components/home/AdultHome.vue'

const { t } = useI18n()
const { selectedGradeType } = storeToRefs(useAuthStore())

setTitle(t('home.title'))
useAppColor({ themeColor: ColorName.Seagreen50, bgColor: ColorName.White })

const componentMap = new Map<GradeType, Component>([
  [GradeType.LowerPrimary, LowerPrimaryHome],
  [GradeType.UpperSecondary, UpperSecondaryHome],
  [GradeType.AdultEducation, AdultHome],
  [GradeType.PrepEducation, PrepHome],
])

const componentToRender = computed(() => selectedGradeType.value
  ? componentMap.get(selectedGradeType.value) || DefaultHome
  : DefaultHome)
</script>

<template>
  <component :is="componentToRender" />
</template>
