<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { colorMap, getColorAndShade } from '~/utils/colors'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useDescriptor from '~/composables/useDescriptor'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'
import CardMenu from '~/components/cards/buttons/CardMenu.vue'

const props = defineProps<{ item: BaseItem; themeColor: string }>()

const { getDescriptor } = useDescriptor()
const { buildResourceUrl } = useUrlBuilder()

const link = computed(() => buildResourceUrl(props.item))
const backgroundColor = computed(() =>  colorMap.get(getColorAndShade(props.themeColor, '10'))?.rgb)
const color = computed(() =>  colorMap.get(getColorAndShade(props.themeColor, '60'))?.rgb)
const label = computed(() => getDescriptor(props.item))
</script>

<template>
  <li
    class="group relative grid size-full grid-cols-3 gap-1 rounded-xl bg-white transition hover:shadow"
    :style="{ color }"
  >
    <div
      class="flex flex-col justify-center px-4 pb-4 pt-8"
      :class="item.thumbnail ? 'col-span-2' : 'col-span-3'"
    >
      <span class="mb-2 text-lg font-bold">
        <LinkOrAnchor
          class="line-clamp-3 outline-0 before:absolute before:inset-0 before:rounded-xl before:transition before:focus-visible:ring"
          :link="link"
        >
          {{ item.title }}
        </LinkOrAnchor>
      </span>
      <span
        class="absolute left-0 top-0 flex items-center gap-1 rounded-br-xl rounded-tl-xl px-4 py-1 text-xs font-medium"
        :style="{ backgroundColor }"
      >
        {{ label }}
      </span>
      <CardMenu :resource="item" />
    </div>
    <div
      v-if="item.thumbnail"
      class="col-span-1 aspect-video size-full overflow-hidden rounded-r-xl"
    >
      <img
        :src="item.thumbnail"
        :alt="item.thumbnailAlt"
        class="size-full object-cover"
      >
    </div>
  </li>
</template>
