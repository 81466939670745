<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import { computed, onBeforeUnmount, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import useSubjectsStore from '~/stores/subjects'
import useFilterStore from '~/stores/filter'
import useAuthStore from '~/stores/auth'
import { GradeType } from '~/models/GradeType'
import useQueryFilter from '~/composables/useQueryFilter'
import { useHeaderQuery } from '~/composables/useHeaderQuery'
import { useContentHelper } from '~/composables/useContentHelper'
import { useContentFilter } from '~/composables/useContentFilter'
import HeaderViewBannerGrid from '~/components/utils/HeaderViewBannerGrid.vue'
import LowerSecondaryHeader from '~/components/subject/gradetypes/LowerSecondaryHeader.vue'
import LowerPrimaryHeader from '~/components/subject/gradetypes/LowerPrimaryHeader.vue'
import ContentFilteredAwayCallout from '~/components/ResourceFinder/ContentFilteredAwayCallout.vue'

const props = defineProps<{
  subjectCode: SubjectCode
  headerLocationId: number
}>()

const filterStore = useFilterStore()
const { selectedGradeType } = storeToRefs(useAuthStore())
const { isLoading: filterIsLoading, selectedHeaders } = storeToRefs(useFilterStore())
const { filteredContents, filteredProductPackages, contents } = useContentFilter()
const { isLoading, refetch, isRefetching } = useHeaderQuery()
const { isPackage } = useContentHelper()
const { filterWatchers } = filterStore
const { setCurrentSubject } = useSubjectsStore()

const contentItems = computed(() => filteredContents.value.filter((content) => !isPackage(content)))
const combinedFiltered = computed(() => [...contentItems.value, ...filteredProductPackages.value])
const combinedContents = computed(() => [...contents.value, ...filteredProductPackages.value])

const headerProps = computed(() => ({
  contents: contentItems.value,
  packages: filteredProductPackages.value,
  isRefetching: isRefetching.value,
  isLoading: isLoading.value,
  header: selectedHeaders.value[0],
}))

const watchers = filterWatchers()

watch(filteredProductPackages, () => refetch())
watch(props, () => {
  setCurrentSubject(props.subjectCode)
}, { immediate: true })

onMounted(() => useQueryFilter())
onBeforeUnmount(() => {
  watchers.stopFilterWatchers()
  setCurrentSubject('')
})
</script>

<template>
  <div
    v-if="!combinedFiltered.length && combinedContents.length > 0 && !filterIsLoading"
    class="mx-auto flex max-w-screen-au flex-col gap-4 px-4 pb-16 pt-36 sm:px-8"
  >
    <ContentFilteredAwayCallout :contents="combinedContents" />
  </div>

  <div v-else-if="!headerProps.header || isLoading || isRefetching">
    <KsSkeletonWrapper>
      <HeaderViewBannerGrid class="py-24">
        <div class="flex gap-2 grid-in-filters">
          <KsSkeleton
            height="2rem"
            width="8rem"
          />
          <KsSkeleton
            height="2rem"
            width="8rem"
          />
        </div>
        <div class="grid-in-header-info">
          <KsSkeleton height="4rem" />
          <KsSkeleton
            height="1.5rem"
            width="80%"
          />
          <KsSkeleton
            height="1.5rem"
            width="70%"
          />
          <KsSkeleton
            height="1.5rem"
            width="80%"
          />
          <KsSkeleton
            height="1.5rem"
            width="65%"
          />
          <KsSkeleton
            height="1.5rem"
            width="70%"
          />
        </div>
      </HeaderViewBannerGrid>
    </KsSkeletonWrapper>

    <!-- these sections are a copy of the skeleton in LowerPrimaryHeader -->
    <section
      v-for="index in 2"
      :key="index"
    >
      <KsSkeletonWrapper class="mx-auto max-w-screen-au space-y-4 px-4 py-8 sm:px-8">
        <KsSkeleton
          height="36px"
          width="120px"
        />
        <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <KsSkeleton
            v-for="i in 4"
            :key="i"
            height="280px"
          />
        </ul>
      </KsSkeletonWrapper>
    </section>
  </div>

  <template v-else>
    <LowerPrimaryHeader
      v-if="selectedGradeType === GradeType.LowerPrimary && combinedFiltered.length > 0"
      v-bind="headerProps"
    />
    <LowerSecondaryHeader
      v-if="selectedGradeType === GradeType.LowerSecondary && combinedFiltered.length > 0"
      v-bind="headerProps"
    />
  </template>
</template>
