<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import LowerPrimarySearch from '~/components/home/LowerPrimarySearch.vue'
import LowerPrimaryBanner from '~/components/home/LowerPrimaryBanner.vue'
import BlogPostSection from '~/components/blog/BlogPostSection.vue'

const { isTeacher } = storeToRefs(useAuthStore())
const { t } = useI18n()
</script>

<template>
  <div class="flex h-full flex-col">
    <LowerPrimaryBanner />
    <LowerPrimarySearch />
    <div class="grow bg-[#F6F9F9]">
      <BlogPostSection
        v-if="isTeacher"
        :limit="3"
      >
        <template #title>
          <h2
            class="font-extrabold fluid-text-xl"
            v-text="t('blog.title')"
          />
        </template>
      </BlogPostSection>
    </div>
  </div>
</template>
