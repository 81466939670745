import { useQuery } from '@tanstack/vue-query'
import useFilterStore from '~/stores/filter'
import { useContentFilter } from '~/composables/useContentFilter'

export function useHeaderQuery() {
  const { getMultipleHeaderChildren } = useFilterStore()
  const { filteredProductPackages } = useContentFilter()

  return useQuery({
    queryKey: ['header-view-query', ...filteredProductPackages.value.map(({ locationId }) => locationId)],
    queryFn: () => Promise.all(filteredProductPackages.value
      .map((content) => getMultipleHeaderChildren([content]))),
    staleTime: Infinity,
  })
}
