<script lang="ts" setup>
import type { Media } from '~/models/Content/Media'
import type { ContentFlashcard, ContentFlashcardDeck } from '~/models/Content/ContentFlashcards'
import type { ContentAudio } from '~/models/Content/ContentAudio'
import { watch, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { vIntersectionObserver } from '@vueuse/components'
import { useQuery } from '@tanstack/vue-query'
import { KsButton, KsIcon, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import useContentApi from '~/api/contentApi'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import FullscreenButton from '~/components/utils/FullscreenButton.vue'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import CloseButton from '~/components/utils/CloseButton.vue'
import Image from '~/components/media/Image.vue'
import AudioPlayer from '~/components/media/AudioPlayer.vue'
import FlashCard from '~/components/flashcards/FlashCard.vue'

const props = defineProps<{
  locationId: number
}>()

const { t } = useI18n()
const { findContents } = useContentApi()

const scroller = ref<HTMLDivElement | null>(null)
const images = ref<Map<number, Media>>(new Map())
const audio = ref<Map<number, ContentAudio>>(new Map())
const isFullscreen = ref(false)

const { data, isLoading } = useQuery({
  queryKey: ['flashcards', props.locationId],
  async queryFn() {
    const [location] = (await findContents<ContentFlashcardDeck>({
      contentTypeCriterion: [ContentType.Flashcards],
      subtreeCriterion: [Subtree.Content],
      locationIdCriterion: [props.locationId],
    }, 1))

    if (location.cards.destinationContentIds.length === 0) {
      return { location, cards: [] }
    }

    const cards = await findContents<ContentFlashcard>({
      contentTypeCriterion: [ContentType.Flashcard],
      subtreeCriterion: [Subtree.Content],
      contentIdCriterion: location.cards.destinationContentIds,
      mainLocationCriterion: true,
    })

    for (const card of cards) {
      if (card.imageFront?.destinationContentId) {
        const contentId = Number(card.imageFront.destinationContentId)
        findContents<Media>({
          subtreeCriterion: [Subtree.Content, Subtree.Media],
          contentIdCriterion: [contentId],
          mainLocationCriterion: true,
        })
        .then(([data]) => { images.value.set(contentId, data) })
      }

      if (card.audioFront?.destinationContentId) {
        const contentId = Number(card.audioFront.destinationContentId)
        findContents({
          subtreeCriterion: [Subtree.Content, Subtree.Media],
          contentTypeCriterion: [ContentType.Audio],
          contentIdCriterion: [contentId],
          mainLocationCriterion: true,
        })
        .then(([data]) => { audio.value.set(contentId, data) })
      }
    }

    return { location, cards }
  }
})

const shuffledCards = ref(shuffle(data.value?.cards ?? []))
const activeCardIndex = ref(-1)
const isLast = ref(true)

const location = computed(() => data.value?.location)

watch(location, async (location) => {
  if (!location) return
  setTitle(location.title)
})

watch(() => data.value?.cards, (newCards, oldCards) => {
  if (newCards && (oldCards?.length !== newCards?.length)) {
    shuffledCards.value = shuffle(newCards)
  }
})

enum Dir {
  Forwards = 1,
  Backwards = -1,
}

function scroll(dir: Dir) {
  const el = scroller.value
  if (!el) return

  el.scrollTo({
    left: el.scrollLeft + dir * el.clientWidth,
    behavior: 'smooth',
  })
}

function reload() {
  scroller.value?.scrollTo({ left: 0 })
  shuffledCards.value = shuffle(shuffledCards.value)
}

function shuffle<T>(cards: T[]): T[] {
  const newCards = [...cards]
  const random = Array.from({ length: newCards.length }, Math.random)
  return newCards.sort((a, b) => random[newCards.indexOf(a)] > random[newCards.indexOf(b)] ? 1 : -1)
}

function updateInView(index: number, inView: boolean) {
  if (inView) activeCardIndex.value = index
}

function updateInViewStartObserver([entry]: IntersectionObserverEntry[]) {
  if (entry.isIntersecting) activeCardIndex.value = -1
}

function updateInViewEndObserver([entry]: IntersectionObserverEntry[]) {
  isLast.value = !entry.isIntersecting
}
</script>

<template>
  <div class="fixed right-4 top-4 z-10 flex items-center gap-4">
    <FullscreenButton
      v-if="location"
      :style="{
        backgroundColor: location.colorPair.background.rgb,
        color: location.colorPair.text.rgb,
        border: `1.5px solid ${location.colorPair.text.rgb}`
      }"
      @fullscreen="isFullscreen = $event"
    />
    <CloseButton
      v-if="location"
      :item="location"
      :style="{
        backgroundColor: location.colorPair.background.rgb,
        color: location.colorPair.text.rgb,
        border: `1.5px solid ${location.colorPair.text.rgb}`
      }"
      :is-fixed="false"
    />
  </div>

  <div
    v-if="isLoading"
    class="h-full"
  >
    <KsSkeletonWrapper class="grid h-full place-content-center place-items-center gap-4">
      <KsSkeleton
        height="4rem"
        width="30ch"
      />
      <KsSkeleton
        height="2rem"
        width="45ch"
      />
      <KsSkeleton
        height="3rem"
        width="20ch"
        class="!rounded-full"
      />
    </KsSkeletonWrapper>
  </div>

  <div
    v-else-if="location"
    ref="scroller"
    tabindex="0"
    class="relative flex h-full snap-x snap-mandatory items-center gap-[--card-spacing] overflow-x-scroll scroll-smooth px-[calc(10vh+var(--card-spacing))]"
    :style="{
      backgroundColor: location.colorPair.background.rgb,
      color: location.colorPair.text.rgb,
      scrollbarWidth: 'none',
      '--media-bg-color': location.colorPair.background.rgb,
      '--media-color': location.colorPair.text.rgb,
      '--card-width': isFullscreen ? '100dvw' : '80dvw',
      '--card-height': isFullscreen ? '100dvh' : '80dvh',
      '--card-spacing': isFullscreen ? '0' : '3rem',
    }"
    @keydown.left.prevent="scroll(Dir.Backwards)"
    @keydown.right.prevent="scroll(Dir.Forwards)"
  >
    <div
      v-intersection-observer="[updateInViewStartObserver, { threshold: .5 }]"
      class="grid h-[--card-height] w-[--card-width] flex-none snap-center snap-always place-content-center place-items-center gap-8"
    >
      <h1 class="text-5xl">
        {{ location.title }}
      </h1>
      <p class="text-xl">
        {{ t('flashcards.tip') }}
      </p>
      <KsButton
        shape="rounded"
        variant="border"
        icon-right="arrow-right"
        :style="{
          background: location.colorPair.background.rgb,
          '--ks-border': location.colorPair.text.rgb,
          '--ks-borderhovertext': location.colorPair.isDark ? 'white' : 'black',
        }"
        size="large"
        @click="scroll(Dir.Forwards)"
      >
        {{ t('flashcards.start') }}
      </KsButton>
    </div>

    <FlashCard
      v-for="({ card, image, audio }, index) of shuffledCards.map((card) => ({
        card,
        image: (card.imageFront && images.get(Number(card.imageFront.destinationContentId))!) || undefined,
        audio: (card.audioFront && audio.get(Number(card.audioFront.destinationContentId))!) || undefined,
      }))"
      :key="card.locationId"
      class="relative grid h-[--card-height] w-[--card-width] flex-none snap-center snap-always place-items-center overflow-hidden rounded-lg bg-white"
      :class="[location.textSizes.paragraph]"
      :style="{
        backgroundColor: location.colorPair.text.rgb,
        color: location.colorPair.background.rgb,
        '--flashcard-safe-inset': isFullscreen ? '4rem' : '0',
      }"
      @view="updateInView(index, $event)"
    >
      <template #front>
        <Image
          v-if="image"
          :content="image"
          class="max-h-full min-h-0 !w-full min-w-0 place-self-stretch"
          :class="image.contentTypeIdentifier === ContentType.Svg ? 'object-contain' : 'object-cover'"
        />
        <RichTextRenderer
          class="p-24"
          :class="{ 'sr-only': image }"
          :text="card.bodyFront ?? 'no text'"
        />
        <CopyrightButton
          v-if="image && 'copyright' in image"
          class="!absolute bottom-8 right-8 !w-auto text-lg"
          :copyright="image.copyright ?? ''"
          @click.stop
        />
      </template>

      <template #back>
        <RichTextRenderer
          class="p-24"
          :class="[location.textSizes.heading]"
          :text="card.bodyBack ?? 'no text'"
        />
      </template>

      <template
        v-if="audio"
        #overlay
      >
        <AudioPlayer
          :media-id="audio.metadata?.contentItemId"
          :src="audio.metadata?.elementURI"
          class="-translate-x-1/2 !rounded-full"
        >
          <template #controls>
            <media-play-button
              tabindex="-1"
              class="group grid h-32 w-32 place-content-center rounded-full bg-[--media-bg-color] text-3xl text-[--media-color] shadow-lg"
            >
              <KsIcon
                id="play-solid"
                class="hidden translate-x-[10%] group-data-[paused]:block"
              />
              <KsIcon
                id="pause"
                class="group-data-[paused]:hidden"
              />
            </media-play-button>
          </template>
        </AudioPlayer>
      </template>
    </FlashCard>

    <div
      v-intersection-observer="[updateInViewEndObserver, { threshold: .5 }]"
      class="grid h-[--card-height] w-[--card-width] flex-none snap-center snap-always place-content-center place-items-center gap-8 bg-[url(/confetti.svg)] bg-center bg-no-repeat"
    >
      <p class="text-5xl">
        {{ t('flashcards.complete') }}
      </p>
      <KsButton
        shape="rounded"
        variant="border"
        icon-left="arrow-left"
        :style="{
          background: location.colorPair.background.rgb,
          '--ks-border': location.colorPair.text.rgb,
          '--ks-borderhovertext': location.colorPair.isDark ? 'white' : 'black',
        }"
        size="large"
        @click="reload()"
      >
        {{ t('flashcards.retry') }}
      </KsButton>
    </div>

    <nav
      class="fixed bottom-6 left-1/2 flex -translate-x-1/2 items-center gap-6 transition-opacity"
      :class="{ 'pointer-events-none opacity-0': activeCardIndex < 0 }"
    >
      <KsButton
        icon-left="arrow-left"
        variant="border"
        shape="round"
        size="large"
        :style="{
          background: location.colorPair.background.rgb,
          '--ks-border': location.colorPair.text.rgb,
          '--ks-borderhovertext': location.colorPair.isDark ? 'white' : 'black',
        }"
        @click="scroll(Dir.Backwards)"
      />
      <div
        class="flex items-center self-stretch rounded px-2 text-2xl tabular-nums"
        :style="{ background: location.colorPair.background.rgb }"
      >
        {{ t('flashcards.stage', {
          n: Math.max(1, activeCardIndex + 1),
          total: shuffledCards?.length ?? 0,
        }) }}
      </div>
      <KsButton
        class="transition-[opacity,visibility]"
        :class="{ 'pointer-events-none invisible opacity-0': !isLast }"
        icon-left="arrow-right"
        variant="border"
        shape="round"
        size="large"
        :style="{
          background: location.colorPair.background.rgb,
          '--ks-border': location.colorPair.text.rgb,
          '--ks-borderhovertext': location.colorPair.isDark ? 'white' : 'black',
        }"
        @click="scroll(Dir.Forwards)"
      />
    </nav>
  </div>

  <div
    v-else
    class="grid h-full place-content-center p-6"
  >
    {{ t('flashcards.error') }}
  </div>
</template>
