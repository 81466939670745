<script setup lang="ts">
import { computed } from 'vue'
import { KsSpinner } from '@aschehoug/kloss'
import useProductStore from '~/stores/product'
import { useProductQuery } from '~/composables/useProductQuery'
import ProductSearchView from '~/components/products/ProductSearchView.vue'
import ProductGenericView from '~/components/products/ProductGenericView.vue'

const props = defineProps<{ locationId: number }>()

const { isSearchableProduct } = useProductStore()
const { data: product, isLoading } = useProductQuery(props.locationId)

const componentMap = computed(() =>
  isSearchableProduct(product.value) ? ProductSearchView : ProductGenericView)
</script>

<template>
  <div
    v-if="isLoading"
    class="absolute inset-0 flex items-center justify-center"
  >
    <KsSpinner />
  </div>
  <component
    :is="componentMap"
    v-else
    :product="product"
  />
</template>
