import type { SubjectCode } from '~/models/Subject'
import type { GroupMember } from '~/models/Group'
import type { ContentType } from '~/models/Content/ContentType'
import type { BaseItem } from '~/models/Content/BaseItem'
import type {
  CompoundedProgress,
  SimpleProgress,
  StudentProgress,
  StudentTaskProgress,
  TaskProgress,
} from '~/models/Activity'
import { ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { DateTime } from 'luxon'
import useArrayUtils from '~/utils/arrayUtils'
import useWeekStore from '~/stores/week'
import usePlannerStore from '~/stores/planner'
import useGroupsStore from '~/stores/groups'
import { useAuthStore } from '~/stores/auth'
import { Section } from '~/models/Content/Section'
import useSearchHelper from '~/composables/useSearchHelper'
import useSearchClient from '~/composables/useSearchClient'
import { useI18n } from '~/composables/useI18n'
import useContentMapper from '~/composables/useContentMapper'
import { useContentHelper } from '~/composables/useContentHelper'
import activityApi from '~/api/activityApi'

const { t } = useI18n()
const { getCompoundProgress, getCompoundedProgressBySubjects, getSimpleActivity } = activityApi()

export const useActivityStore = defineStore('activity',  () => {
  const isEnrichingActivities = ref(false)

  const createTaskListFromSimple = (simpleProgress: SimpleProgress): TaskProgress[] => {
    const { selectedWeekNumber } = storeToRefs(useWeekStore())
    const { isResourceInSelectedYearPlanByWeek } = usePlannerStore()

    return simpleProgress.progress.map((task) => {
      const title = ('nb-NO' in task.title)
        ? task.title['nb-NO']
        : task.title['nn-NO']

      const resource: TaskProgress = {
        title: title,
        shortTitle: title,
        contentId: 0,
        locationId: parseInt(task.pageId || '0'),
        mainLocationId: parseInt(task.pageId || '0'),
        parentLocationId: task.pathArray[task.pathArray.length - 2] || 0,
        contentTypeIdentifier: task.contentType as ContentType,
        sectionId: Section.Standard,
        pathString: `/${task.pathArray.join('/')}/`,
        pathArray: task.pathArray,
        publishedDate: '',
        grades: [],
        intro: '',
        label: task.contentType,
        thumbnail: task.image.replace('/kort', '/thumbnail'),
        thumbnailAlt: '',
        sortField: 'priority',
        sortOrder: 'asc',
        children: [],
        subjects: task.subjects,
        noOfUsers: task.noOfUsers,
        learningPathPartsCount: task.learningPathPartsCount,
        planned: false,
      }
      resource.planned = isResourceInSelectedYearPlanByWeek(resource, selectedWeekNumber.value)
      return resource
    })
  }

  const createStudentListFromCompounded = (compoundedProgress: CompoundedProgress): StudentProgress[] => {
    const { activeGroupStudents } = useGroupsStore()

    return activeGroupStudents.map((student) => {
      const activity = compoundedProgress.progress.find((progress) => progress.userId === student.userId)
      const interaction = activity?.packages[0]?.lastInteraction || undefined
      let status = t('activity.status.notStarted')
      if (activity) status = t('activity.status.visited')

      return {
        user: student,
        status: status,
        lastInteraction: interaction ? DateTime.fromISO(interaction).toLocaleString({ month: 'long', day: '2-digit' }) : '',
        read: activity?.packages[0]?.read || 0,
        sectionCount: activity?.packages[0]?.sectionCount || 0,
        progress: activity?.packages[0]?.progress ? Math.round((activity?.packages[0]?.progress * 100)) : 0,
        formWriterId: activity?.packages[0]?.formWriterId || '',
        locationId: parseInt(activity?.packages[0].pageId || '0'),
      }
    }).sort((a, b) => (a.user.fullName.toUpperCase().localeCompare(b.user.fullName.toUpperCase())))
  }

  const createActivitiesFromCompounded = (compoundedProgress: CompoundedProgress) => {
    const packages = compoundedProgress.progress[0]?.packages

    const studentActivities = packages?.map((pkg) => {
        return {
          title: '',
          locationId: parseInt(pkg.pageId || '0'),
          totalUsers: undefined,
          visitedUsers: undefined,
          lastInteraction: pkg.lastInteraction,
          progress: Math.round((pkg.progress * 100)),
          read: pkg.read,
          sectionCount: pkg.sectionCount,
        }
    })

    return studentActivities || []
  }

  const loadCompoundProgressByDatesAndLocationIds = async (locationIds: number[], fromDate?: string, toDate?: string): Promise<StudentProgress[]> => {
    if (!locationIds.length) return []
    if (!Number.isInteger(locationIds[0])) {
      throw new Error(`Expected locationIds to be a number, but got ${typeof(locationIds[0])}`)
    }
    const { activeGroupStudentIds } = useGroupsStore()

    if(locationIds.length === 0 || activeGroupStudentIds.length === 0) {
      return []
    }

    let compoundedProgress: CompoundedProgress | undefined = undefined
    try {
      compoundedProgress = filterByToDate<CompoundedProgress>(await getCompoundProgress(activeGroupStudentIds, locationIds.map(String), fromDate), toDate)
    } catch (e) {
      console.error(`Could not load compounded activity at ${locationIds} for users ${activeGroupStudentIds}`)
      throw e
    }

    return createStudentListFromCompounded(compoundedProgress)
  }

  const loadSimpleActivitiesByDates = async (subjects: SubjectCode[], fromDate: string, toDate: string): Promise<TaskProgress[]> => {
    const { activeGroupStudentIds } = useGroupsStore()

    try {
      return createTaskListFromSimple(
        filterByToDate<SimpleProgress>(await getSimpleActivity(activeGroupStudentIds, subjects, fromDate), toDate)
      )
    } catch (e) {
      console.error(`Could not load simple activity between ${fromDate} and ${toDate} for group ${activeGroupStudentIds}`)
      throw e
    }
  }

  const createStudentProgressFromCompounded = (compoundedProgress: CompoundedProgress): StudentProgress[] => {
    const { activeGroupStudents, mapGroupMember } = useGroupsStore()
    const { user, isTeacher } = storeToRefs(useAuthStore())
    if (!user.value) throw new Error('User not logged in')

    let students: GroupMember[] = [...activeGroupStudents]
    if (!isTeacher.value && activeGroupStudents.length === 0) {
      students = [mapGroupMember(user.value)]
    }

    if (students.length === 0) {
      return []
    }

    return students.flatMap((student) => {
      const activity = compoundedProgress.progress.find((progress) => progress.userId === student.userId)
      if (!activity) return []
      return activity.packages.map((pkg) => {
        const interaction = pkg.lastInteraction || undefined
        let status = t('activity.status.notStarted')
        if (activity) status = t('activity.status.visited')

        return {
          user: student,
          status: status,
          lastInteraction: interaction ? DateTime.fromISO(interaction).toLocaleString({ month: 'long', day: '2-digit' }) : '',
          read: pkg.read || 0,
          sectionCount: pkg.sectionCount || 0,
          progress: pkg.progress ? Math.round((pkg.progress * 100)) : 0,
          formWriterId: pkg.formWriterId || '',
          locationId: parseInt(pkg.pageId || '0'),
        }
      })
    }).sort((a, b) => (a.user.fullName.toUpperCase().localeCompare(b.user.fullName.toUpperCase())))
  }

  const loadActivitiesByDatesForStudent = async (fromDate: string, toDate: string): Promise<StudentTaskProgress[]> => {
    const { user, userSubjectsByGrades } = storeToRefs(useAuthStore())
    if (!user.value) throw new Error('User not logged in')

    try {
      const taskProgress = createTaskListFromSimple(
        filterByToDate<SimpleProgress>(await getSimpleActivity([user.value.username], userSubjectsByGrades.value, fromDate), toDate)
      )
      const studentProgress = createStudentProgressFromCompounded(
        filterByToDate<CompoundedProgress>(await getCompoundedProgressBySubjects([user.value.username], userSubjectsByGrades.value, fromDate), toDate)
      )
      return createStudentTaskProgress(taskProgress, studentProgress)
    } catch (e) {
      console.error(`Could not load simple activity between ${fromDate} and ${toDate} for user ${user.value.username}`)
      throw e
    }
  }

  const isCompoundedProgress = (progress: CompoundedProgress | SimpleProgress): progress is CompoundedProgress => {
    return (progress as CompoundedProgress).userCountPerPackage !== undefined
  }

  const isSimpleProgress = (progress: CompoundedProgress | SimpleProgress): progress is SimpleProgress => {
    return (progress as SimpleProgress).totalNumUsers !== undefined
  }

  const filterByToDate = <T extends CompoundedProgress | SimpleProgress>(progress: T, toDate?: string): T => {
    if (!toDate) return progress
    if (isCompoundedProgress(progress)) {
      const compoundedProgress = progress
      const filteredProgress = compoundedProgress.progress.filter((progress) => progress.packages.some((pkg) => pkg.lastInteraction.slice(0, 10) <= toDate))
      return { ...compoundedProgress, progress: filteredProgress }
    }
    if (isSimpleProgress(progress)) {
      const simpleProgress = progress
      const filteredProgress = simpleProgress.progress.filter((progress) => progress.lastTimestamp.slice(0, 10) <= toDate)
      return { ...simpleProgress, progress: filteredProgress }
    }
    console.warn('Could not filter progress by toDate, because the type was not recognized')
    return progress
  }

  const createStudentTaskProgress = (taskProgress: TaskProgress[], studentProgress: StudentProgress[]): StudentTaskProgress[] => {
    const { truthy } = useArrayUtils()
    return taskProgress.map((task) => {
      const studentProgressForTask = studentProgress.find((sp) => sp.locationId === task.locationId)
      if (!studentProgressForTask) return undefined
      return {
        ...task,
        ...(studentProgress.find((sp) => sp.locationId === task.locationId)),
      }
    }).filter(truthy<StudentTaskProgress>)
  }

  const enrichActivitiesWithSearch = async <T extends TaskProgress>(activities: T[]): Promise<T[]> => {
    isEnrichingActivities.value = true
    const { mapBaseFields, mapLocations } = useContentMapper()
    const { getTitle } = useContentHelper()
    const locationIds = activities.map(({ locationId }) => Number(locationId))
    const { searchPath, emptyQuery, getLocationIdCriterion } = useSearchHelper()
    const { results, fetchResults } = useSearchClient<BaseItem[]>(searchPath.value, {
      transformData: (response) => mapLocations(response.View.Result).map(mapBaseFields),
    })
    await fetchResults(emptyQuery, { ...getLocationIdCriterion(locationIds) })

    const enrichedActivities = activities.map((activity) => {
      if (!results.value) return activity
      const baseItem = results.value.find((result) => result.locationId === activity.locationId)
      if (!baseItem) return activity

      return {
        ...activity,
        ...baseItem,
        title: getTitle(baseItem),
      }
    })
    isEnrichingActivities.value = false
    return enrichedActivities
  }

  return {
    isEnrichingActivities,
    loadCompoundProgressByDatesAndLocationIds,
    loadSimpleActivitiesByDates,
    loadActivitiesByDatesForStudent,
    enrichActivitiesWithSearch,
  }
})

export default useActivityStore
