<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsCallout } from '@aschehoug/kloss'
import { useMessagesStore } from '~/stores/messages'
import { LoginName, LoginSource } from '~/models/Login'
import usePaywallOverlay from '~/composables/usePaywallOverlay'
import useLogin from '~/composables/useLogin'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import AuLogo from '~/components/utils/AuLogo.vue'

const { t } = useI18n()
const { createLoginUrl, loginButtonClick } = useLogin()
const { hasLoginMessage, getMessages, messages } = useMessagesStore()
const { washPage } = usePaywallOverlay()

const currentUrl = computed(() => window.location.href)
const webshopUrl = computed(() => <string>import.meta.env.VITE_WEBSHOP_TRIALURL)
</script>

<template>
  <Transition
    tag="div"
    class="paywall-overlay-ignore z-20"
    enter-active-class="duration-500 delay-500"
    enter-from-class="translate-y-full opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    appear
    paywall-overlay-ignore
    @after-enter="washPage"
  >
    <div class="absolute h-screen w-screen bg-gradient-to-b from-transparent via-white to-white">
      <div class="container mx-auto h-screen">
        <div class="absolute inset-x-0 bottom-10 z-20 m-auto h-fit max-w-prose rounded-lg border-gray-10 bg-white p-10 text-center sm:bottom-32 sm:border-2">
          <div class="mb-3 inline-flex rounded-full p-2 text-seagreen-50">
            <AuLogo class="mx-auto h-24 w-auto pt-8 text-black" />
          </div>
          <p
            class="mb-5 mt-3"
            v-text="t('login.intro')"
          />
          <KsCallout
            v-if="hasLoginMessage"
            :title="messages?.loginMessageTitle"
            variant="warning"
            class="mb-8"
          >
            <RichTextRenderer :text="messages?.loginMessage" />
          </KsCallout>
          <div class="grid-cols-l place-items-centergap-4 sm:gap grid w-full gap-4 px-10 sm:grid-cols-2 md:grid-cols-2">
            <KsButton
              :href="createLoginUrl(LoginSource.Feide, currentUrl)"
              variant="primary"
              shape="rounded"
              @click.once.prevent="loginButtonClick(LoginName.Feide, createLoginUrl(LoginSource.Feide, currentUrl), $event)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                class="size-5"
              >
                <path
                  fill="currentColor"
                  d="M16.61 10.346h-1.739v4.175h1.74v-4.175ZM10.87 11.563H9.13v4.697H5.129v-5.914h-1.74v6.262h.009V18H16.61v-1.74h-5.74v-4.697ZM10 9.824a1.044 1.044 0 1 0 0-2.088 1.044 1.044 0 0 0 0 2.088Z"
                />
                <path
                  fill="currentColor"
                  d="M10 3.74a4.872 4.872 0 0 1 4.866 4.866h1.74A6.613 6.613 0 0 0 10 2a6.613 6.613 0 0 0-6.606 6.606h1.741A4.87 4.87 0 0 1 10 3.74Z"
                />
              </svg>
              {{ t('login.withServiceProvider') }}
            </KsButton>
            <KsButton
              :href="createLoginUrl(LoginSource.Local, currentUrl)"
              variant="secondary"
              shape="rounded"
              icon-left="user"
              @click.once.prevent="loginButtonClick(LoginName.Local, createLoginUrl(LoginSource.Local, currentUrl), $event)"
            >
              {{ t('login.withLocalProvider') }}
            </KsButton>
          </div>
          <div class="grid-cols-l grid w-full place-items-center gap-4 px-10 py-4 sm:py-6">
            <KsButton
              variant="tertiary"
              size="medium"
              :icon-left="faUpRightFromSquare"
              :href="webshopUrl"
              target="_blank"
            >
              {{ t('login.more') }}
            </KsButton>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
