<script setup lang="ts">
import type { ContentFlytTask } from '~/models/Content/ContentFlytTask'
import { onBeforeMount, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { setTitle } from '~/utils/dom'
import useLicenseControlStore from '~/stores/licenseControl'
import useAuthStore from '~/stores/auth'
import { ContentType } from '~/models/Content/ContentType'
import { ColorName } from '~/models/Content/Color'
import { useAppColor } from '~/composables/useAppColor'
import useContentApi from '~/api/contentApi'
import TopRightBar from '~/components/utils/TopRightBar.vue'
import FlytTask from '~/components/flyt/FlytTask.vue'
const props = defineProps<{ locationId: number }>()

const { checkItemLicense } = useLicenseControlStore()
const { findContents } = useContentApi()
const { isAuthenticated } = storeToRefs(useAuthStore())

const content = ref<ContentFlytTask>()
const isLoading = ref(false)

const loadTask = async (locationId: number) => {
  try {
    isLoading.value = true
    const contentValue = (await findContents<ContentFlytTask>({
      locationIdCriterion: [locationId],
      contentTypeCriterion: [ContentType.FlytTask],
    }, 1))[0]
    if (!contentValue) throw Error(`Task ${locationId} not found`)
    content.value = contentValue
  } catch (e) {
    content.value = undefined
    throw e
  } finally {
    isLoading.value = false
  }
  setTitle(content.value?.title)
}

useAppColor(ColorName.Orange10)

onBeforeMount(async () => loadTask(props.locationId))

watch(props, () => loadTask(props.locationId))

watch(content, () => checkItemLicense(content.value))
</script>

<template>
  <header v-if="content">
    <h1
      class="sr-only"
      v-text="content.shortTitle || content.title"
    />
    <TopRightBar
      variant="white"
      :item="content"
    />
  </header>
  <FlytTask
    v-if="content && isAuthenticated"
    :task-id="content.taskId"
    class="mx-1 mb-12 mt-20 rounded-xl bg-[#ffead1ff] p-6 font-inter max-md:px-4 sm:mx-4 lg:mx-auto lg:max-w-[var(--flyt-ideal-content-width-wide)]"
  />
</template>
