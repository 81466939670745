<script setup lang="ts">
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import type { ColorName } from '~/models/Content/Color'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, nextTick, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsIcon, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { colorMap } from '~/utils/colors'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import useAuthStore from '~/stores/auth'
import { QueryParamKey } from '~/models/QueryParamKeys'
import { PendoFeature } from '~/models/Pendo'
import { ContentType } from '~/models/Content/ContentType'
import { useScrollBehavior } from '~/composables/useScrollBehavior'
import { useMedia } from '~/composables/useMedia'
import { useAppColor } from '~/composables/useAppColor'
import useQueryParams from '~/composables/queryParams'
import useContentApi from '~/api/contentApi'
import ScrollButton from '~/components/utils/ScrollButton.vue'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import HeaderViewBannerGrid from '~/components/utils/HeaderViewBannerGrid.vue'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import HeaderTeacherButton from '~/components/subject/HeaderTeacherButton.vue'
import SubjectFilter from '~/components/ResourceFinder/SubjectFilter.vue'
import HeaderFilter from '~/components/ResourceFinder/HeaderFilter.vue'
import GradeFilter from '~/components/ResourceFinder/GradeFilter.vue'
import Image from '~/components/media/Image.vue'
import ColorCard from '~/components/cards/ColorCard.vue'

useScrollBehavior()

const props = defineProps<{
  header: ContentProductHeader
  contents: BaseItem[]
  packages: BaseItem[]
  isLoading: boolean
  isRefetching: boolean
}>()

const { t } = useI18n()
const { productHeaders } = storeToRefs(useFilterStore())
const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())
const { getProductFromPathString } = useProductStore()
const { isTeacher } = storeToRefs(useAuthStore())
const { set: setAppColor } = useAppColor()
const route = useRoute()
const router = useRouter()
const { findContents } = useContentApi()
const { get: getFilters } = useQueryParams<{ [QueryParamKey.SubHeaders]: number[] }>(QueryParamKey.Filters)

const mediaContentId = computed(() => props.header?.backgroundMedia && Number(props.header.backgroundMedia.destinationContentId))
const { media: bgMedia, isLoading: bgMediaIsLoading } = useMedia(mediaContentId)

const primaryColor = computed(() => props.header.colorPair?.primary)
const colorPair = computed(() => props.header.colorPair)
const skeletonColor = computed(() => getColor(colorPair.value?.secondary))
const dropdownBorderColor = computed(() => getColor(colorPair.value?.dark))
const isDark = computed(() => props.header.colorPair?.isDark)
const hasHeaders = computed(() => productHeaders.value.learningPath
  .some(({ locationId }) => locationId === props.header.locationId)
)
const showTeacherButton = computed(() => isTeacher.value
  && hasLoadedProducts.value
  && props.header
)

const bannerColor = computed(() => {
  const backgroundColor = props.header?.backgroundColor as ColorName
  return `background: ${colorMap.get(backgroundColor)?.rgb}`
})

const sectionColors = computed(() => {
  if (!colorPair.value) return []

  if (!props.packages.length) {
    return `background-color: ${getColor(colorPair.value?.dark)}; color: ${getColor(colorPair.value?.accent)};`
  } else {
    return props.packages.map((_, index) => {
      const backgroundColor = index % 2 === 0 ? getColor(colorPair.value?.dark) : getColor(colorPair.value?.primary)
      const textColor = isDark.value ? getColor(colorPair.value?.accent) : getColor(colorPair.value?.secondary)

      return `background-color: ${backgroundColor}; color: ${textColor};`
    })
  }
})

const getColor = (colorName: ColorName | undefined) => {
  if (!colorName) return ''
  const color = colorMap.get(colorName)
  return color ? color.rgb : ''
}

const productData = computed(() => getProductFromPathString(props.header.pathString))

const { data: product, isLoading: productIsLoading } = useQuery({
  queryKey: ['product', props.header.pathString],
  async queryFn() {
    if (!productData.value) return null

    const [content] = await findContents<ContentProduct>({
      locationIdCriterion: [productData.value.aunivers.locationId],
      contentTypeCriterion: [ContentType.Product],
    }, 1)

    return content
  },
  enabled: computed(() => productData.value != null),
})

onMounted(() => {
  if (primaryColor.value) setAppColor(props.header.backgroundColor as ColorName)
})

watch(() => props.packages, () => updateHash())
watch([() => getFilters().shs, () => props.isLoading, () => props.isRefetching], () => updateHash(), { immediate: true })

function updateHash() {
  const [locationId] = getFilters().shs ?? []
  const index = locationId
    ? props.packages.findIndex((content) => locationId === content.locationId)
    : -1

  if (index < 0) return

  router
    .replace({ ...route, hash: `#${index + 1}` })
    .then(() => document
      .getElementById(`${index + 1}`)
      ?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    )
}

</script>

<template>
  <HeaderViewBannerGrid :style="bannerColor">
    <h1
      class="sr-only"
      v-text="props.header.title"
    />
    <div
      id="top-dropdowns"
      class="flex gap-2 pt-24 grid-in-filters"
      style="--ks-input: white"
    >
      <SubjectFilter />
      <KsSkeleton
        v-if="productIsLoading"
        height="100%"
        width="10rem"
        border-radius="10rem"
        background="#fff7"
      />
      <GradeFilter v-else-if="product && product.showGradeFilter" />
    </div>
    <div class="flex flex-col items-start gap-4 self-center pb-24 grid-in-header-info">
      <div
        v-if="hasHeaders"
        class="space-y-2"
      >
        <span
          class="text-sm font-bold uppercase tracking-wider"
          v-text="t('filters.chooseTheme')"
        />
        <HeaderFilter
          id="header-filter"
          style="--ks-input: white"
          :show-numbered-headers="false"
          :index="0"
          full-width
          navigate
        />
      </div>
      <article
        v-if="props.header.intro"
        class="prose space-y-2 font-medium text-[currentColor] fluid-text-lg prose-p:my-0 hover:prose-a:text-seagreen-50 focus-visible:prose-a:ring"
      >
        <RichTextRenderer :text="props.header.intro" />
      </article>
      <HeaderTeacherButton
        v-if="showTeacherButton"
        :selected-header="props.header"
        :data-pendo="PendoFeature.TeacherContent.Header"
        variant="secondary"
        :style="{
          '--ks-input': 'rgb(var(--au-yellow-30))',
          '--ks-inputhover': 'rgb(var(--au-yellow-20))',
          'border': '2px solid rgb(var(--au-yellow-60))',
        }"
      />
    </div>
    <Image
      v-if="mediaContentId"
      :content="mediaContentId"
      :background="bgMediaIsLoading"
      height="0px"
      width="700px"
      class="aspect-video !h-auto min-h-full w-full max-w-full place-self-end object-cover object-left-bottom grid-in-image md:h-0 md:max-w-none md:grid-in-[image-top_/_image-top_/_image]"
      :class="!bgMediaIsLoading && 'animate-fade'"
    />
    <CopyrightButton
      v-if="bgMedia && 'copyright' in bgMedia"
      :copyright="bgMedia.copyright || ''"
      class="m-4 place-self-end row-end-image col-end-image"
    />
  </HeaderViewBannerGrid>
  <template v-if="isLoading || isRefetching">
    <section
      v-for="index in 2"
      :key="index"
      :style="sectionColors[index]"
    >
      <KsSkeletonWrapper class="mx-auto max-w-screen-au space-y-4 px-4 py-8 sm:px-8">
        <KsSkeleton
          height="36px"
          width="120px"
          :background="skeletonColor"
        />
        <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <KsSkeleton
            v-for="i in 4"
            :key="i"
            height="280px"
            :background="skeletonColor"
          />
        </ul>
      </KsSkeletonWrapper>
    </section>
  </template>
  <template v-else>
    <section
      v-for="(content, index) in packages"
      :key="content.contentId"
      :style="sectionColors[index]"
    >
      <component
        :is="header.collapsible ? 'details' : 'div'"
        :id="`${index+1}`"
        class="mx-auto max-w-screen-au space-y-4 overflow-hidden px-4 py-12 sm:px-8"
        :open="`#${index+1}` === route.hash"
      >
        <h2
          v-if="!header.collapsible"
          class="font-calistoga fluid-text-2xl"
          v-text="content.title"
        />

        <summary
          v-else
          class="relative z-10 flex cursor-pointer select-none items-center transition-all focus-visible:ring"
        >
          <h2
            class="font-calistoga fluid-text-2xl"
            v-text="content.title"
          />

          <KsIcon
            class="ml-auto rotate-90 transition-transform duration-300 ease-out [details:not([open])_&]:rotate-0 [details[open]_&]:invisible [details[open]_&]:absolute"
            icon="plus"
            scale="2"
          />

          <KsIcon
            class="ml-auto -rotate-90 transition-transform duration-300 ease-out [details:not([open])_&]:invisible [details:not([open])_&]:absolute [details[open]_&]:rotate-0"
            icon="minus"
            scale="2"
          />
        </summary>

        <ul class="grid grid-cols-1 gap-8 pb-4 supports-[selector(::details-content)]:transition-all supports-[selector(::details-content)]:duration-300 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 [details:not([open])_&]:pointer-events-none [details:not([open])_&]:!mt-0 [details:not([open])_&]:!h-0 [details:not([open])_&]:!pb-0 [details[open]_&]:h-auto">
          <li
            v-for="(childContent, index) in content.children"
            :key="childContent.locationId"
            class="supports-[selector(::details-content)]:transition-all supports-[selector(::details-content)]:delay-[--delay] supports-[selector(::details-content)]:duration-300 [details:not([open])_&]:-translate-y-12 [details:not([open])_&]:opacity-0"
            :style="{
              '--delay': `${index * 50}ms`,
            }"
          >
            <ColorCard
              :resource="childContent"
              :loading="index === 0 ? 'eager' : 'lazy'"
            />
          </li>
        </ul>
      </component>
    </section>
  </template>
  <section
    v-if="contents.length > 0"
    :style="props.packages.length
      ? sectionColors[props.packages.length % sectionColors.length]
      : sectionColors
    "
  >
    <div class="mx-auto flex max-w-screen-au flex-col gap-4 px-4 pb-16 pt-12 sm:px-8">
      <h2
        class="font-calistoga fluid-text-2xl"
        v-text="t('labels.other')"
      />
      <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <li
          v-for="content in contents"
          :key="content.locationId"
        >
          <ColorCard :resource="content" />
        </li>
      </ul>
    </div>
  </section>
  <ScrollButton v-if="!isLoading && !isRefetching" />
</template>

<style scoped>
:deep(.ks-dropdown .ks-dropdown-list ul) {
  max-height: 620px;
  font-size: 1.15rem;
}

#top-dropdowns :deep(input) {
  font-size: .9rem;
}

#top-dropdowns :deep(.ks-dropdown svg) {
  font-size: .9rem;
}

#top-dropdowns :deep(.ks-dropdown .ks-dropdown-list ul) {
  font-size: .9rem;
}

#header-filter :deep(input) {
  font-size: 1.4rem;
  padding: .6em;
  height: 2.1em;
  padding-right: 4rem!important;
  font-family: 'Calistoga', serif;
}
#header-filter :deep(svg) {
  top: 15px;
}

#header-filter :deep(button) {
  top: 12px;
}

@media (min-width: 900px) {
  #header-filter :deep(input) {
    font-size: 1.9rem;
    padding: .6em;
    height: 2.1em;
  }
  #header-filter :deep(svg) {
    top: 24px;
  }

  #header-filter :deep(button) {
    top: 21px;
  }
}

#header-filter :deep(input) {
  border: 1px solid v-bind(dropdownBorderColor);
  border-radius: 10px;
}

details::details-content {
  transition: content-visibility .3s allow-discrete;
}
</style>
