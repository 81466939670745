<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQueryClient } from '@tanstack/vue-query'
import { KsButton, KsIcon, KsInput } from '@aschehoug/kloss'
import { debounce } from '~/utils/functionUtils'

const props = defineProps<{ title: string; searchQuery: string; placeholder: string; queryKey: (string | Record<string, string[]> | number)[] }>()

const { t } = useI18n()
const queryClient = useQueryClient()
const emit = defineEmits(['updateSearchQuery'])

const onInput = debounce((event: Event) => {
  queryClient.cancelQueries({ queryKey: props.queryKey })
  emit('updateSearchQuery', (event.target as HTMLInputElement).value)
}, 700)
</script>

<template>
  <div class="space-y-3 grid-in-search">
    <h1
      class="text-3xl font-extrabold text-violet-50"
      v-text="title"
    />
    <form
      role="search"
      class="relative"
      style="--ks-input: white; --ks-inputhover: white"
      @submit.prevent="() => {}"
    >
      <KsIcon
        icon="magnifying-glass"
        class="absolute left-5 top-1/2 z-10 -translate-y-1/2 text-xl"
      />
      <KsInput
        v-focus
        :value="searchQuery"
        type="text"
        maxlength="200"
        name="search"
        :aria-label="t('search.title')"
        class="!rounded !pl-16 !text-xl placeholder:text-lg placeholder:text-gray-50"
        :placeholder="placeholder"
        @input="onInput"
      />
      <KsButton
        v-if="searchQuery.length"
        icon-left="xmark"
        shape="square"
        variant="tertiary"
        size="small"
        class="absolute right-5 top-1/2 z-10 -translate-y-1/2 text-xl"
        @click="emit('updateSearchQuery', '')"
      />
    </form>
  </div>
</template>
