<script setup lang="ts">
import type { ContentPackage } from '~/models/Content/ContentPackage'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { KsButton, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { useContentHelper } from '~/composables/useContentHelper'
import useContentApi from '~/api/contentApi'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import ProductSearchSkeleton from '~/components/skeletons/ProductSearchSkeleton.vue'
import BookCard from '~/components/cards/BookCard.vue'

const props = defineProps<{
  results: BaseItem[]
  locationId: number[]
  isLoading: boolean
  productTitle: string
}>()

const { t } = useI18n()
const { findContents } = useContentApi()
const { packageTypes } = useContentHelper()
const router = useRouter()
const route = useRoute()

const { data: packageData, isLoading: packageLoading } = useQuery({
  queryKey: computed(() => ['package-content', props.locationId]),
  queryFn: async () => (await findContents<ContentPackage>({
    locationIdCriterion: props.locationId,
    contentTypeCriterion: packageTypes,
    mainLocationCriterion: true,
  }, 1))[0],
  enabled: computed(() => !!props.locationId.length),
  staleTime: Infinity,
})

const clearParentLocationId = () => router.resolve({ query: { ...route.query, parentLocationId: [] } }).fullPath
</script>

<template>
  <KsSkeletonWrapper
    v-if="packageLoading"
    class="mb-8 grid grid-cols-2 items-center gap-4"
  >
    <div>
      <KsSkeleton
        class="mb-4"
        width="80%"
        height="30px"
      />
      <KsSkeleton width="90%" />
      <KsSkeleton width="70%" />
      <KsSkeleton width="60%" />
      <KsSkeleton width="80%" />
    </div>
    <KsSkeleton
      border-radius="0"
      height="240px"
    />
  </KsSkeletonWrapper>
  <article
    v-if="!packageLoading && packageData"
    class="mb-8 animate-fade space-y-4"
  >
    <RouterLink
      class="inline-flex w-max rounded-xl transition focus-visible:ring"
      :to="{ path: clearParentLocationId() }"
    >
      <KsButton
        type="label"
        variant="border"
        icon-left="arrow-left"
      >
        {{ t('search.product.back', { product: productTitle }) }}
      </KsButton>
    </RouterLink>
    <div class="grid grid-cols-1 items-center gap-4 rounded bg-purple-10/50 md:grid-cols-2">
      <div class="space-y-2 p-8">
        <h1
          class="text-2xl font-bold"
          v-text="packageData?.title || ''"
        />
        <RichTextRenderer :text="packageData?.intro || ''" />
      </div>
      <div class="aspect-[3/2] rounded-r bg-purple-20/20 p-8">
        <img
          v-if="packageData?.thumbnail"
          :src="packageData?.thumbnail || ''"
          class="size-full rounded object-cover"
          alt=""
        >
      </div>
    </div>
  </article>
  <ProductSearchSkeleton v-if="isLoading" />
  <ul
    v-else
    class="grid auto-rows-max grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
  >
    <li
      v-for="result in results"
      :key="`book-${result.locationId}`"
      class="row-span-2 grid grid-rows-subgrid"
    >
      <BookCard :item="result" />
    </li>
  </ul>
</template>
