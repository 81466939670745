import type { User } from '~/models/User/User'
import type { SubjectCode } from '~/models/Subject'
import type { Group, GroupMember } from '~/models/Group'
import type { GradeCode } from '~/models/Grade'
import { computed, defineAsyncComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { defineStore, storeToRefs } from 'pinia'
import { waitFor } from '~/utils/asyncUtils'
import useDialogStore from '~/stores/dialog'
import { useAuthStore } from '~/stores/auth'
import { UserRole } from '~/models/User/UserRole'
import { GroupRole, GroupSource, GroupType } from '~/models/Group'
import useGroupsApi from '~/api/groupsApi'

const useGroupsStore = defineStore('groups', () => {
  const { t } = useI18n()
  const { getGroupMembers, putGroupMembers, getGroups, putGroup, postGroup, deleteGroup } = useGroupsApi()
  const authStore = useAuthStore()
  const { setUserActiveGroup } = authStore
  const { user, username, activeUserGroups, isTeacher } = storeToRefs(authStore)
  const groups = ref<Group[]>([])
  const isLoading = ref<boolean>(false)
  const groupMembers = ref<Record<string, GroupMember[]>>({})

  const loadGroups = async () => {
    // Wait for user to be loaded, in order to check if the user is a teacher and then load groups
    await waitFor(() => !!user.value, 2500)
    if (!isTeacher.value) return
    isLoading.value = true
    try {
      groups.value = (await getGroups())
        .map((group) => ({
          // @ts-ignore Temporary fix for groups not having subjects in production APIs (yet). Remove when fixed.
          subjects: [],
          ...group,
        }))
    } catch (error) {
      groups.value = []
      groupMembers.value = {}
      throw error
    } finally {
      isLoading.value = false
    }
  }

  const basisGroups = computed((): Group[] => groups.value
    .filter(({ groupType }) => groupType === GroupType.Basis)
    .sort(sortGroups)
  )
  const teachingGroups = computed((): Group[] => groups.value
    .filter(({ groupType }) => groupType === GroupType.Teacher)
    .sort(sortGroups)
  )
  const otherGroups = computed((): Group[] => groups.value
    .filter(({ groupType }) => [GroupType.Adhoc, GroupType.Other].includes(groupType))
    .sort(sortGroups)
  )

  const hasGroups = computed(() => !!groups.value.length)

  const sortGroups = (a: Group, b: Group): number => a.name.localeCompare(b.name, undefined, {
    numeric: true,
    sensitivity: 'base'
  })
  const sortMembers = (a: GroupMember, b: GroupMember): number => a.fullName.localeCompare(b.fullName, undefined, {
    numeric: true,
    sensitivity: 'base'
  })

  function isAdminInGroup(group: Group) {
    if (group.ownerUserId === username.value) return true
    return findGroupMembers(group)
      .some((member) => member.userId === username.value && member.groupRole === GroupRole.Admin)
  }

  const findGroupById = (groupId: string) => groups.value.find((group) => group.groupId === groupId)
  const findGroupMembers = (group: Group): GroupMember[] => Object.values(groupMembers.value[group.groupId] || [])
  const hasGroupMembers = (group: Group) => findGroupMembers(group).length > 0
  const hasStudents = (group: Group) => findGroupMembers(group).filter((member) => member.role === UserRole.Student).length > 0

  async function loadGroupMembers(group: Group) {
    if (findGroupMembers(group).length) return
    if (!group.groupId) return
    try {
      isLoading.value = true
      groupMembers.value[group.groupId] = await getGroupMembers(group.groupId)
    } catch (error) {
      groupMembers.value[group.groupId] = []
      throw error
    } finally {
      isLoading.value = false
    }
  }

  async function updateGroup(group: Group) {
    const updatedGroup = await putGroup(group)
    const index = groups.value.findIndex(({ groupId }) => groupId === updatedGroup.groupId)
    if (index > -1) {
      groups.value.splice(index, 1, updatedGroup)
    } else {
      groups.value.push(updatedGroup)
    }
    return updatedGroup
  }

  async function updateGroupMembers(group: Group, members: GroupMember[]) {
    const newGroupMembers = await putGroupMembers(group.groupId, members)
    groupMembers.value[group.groupId] = [...newGroupMembers ?? []]
    return newGroupMembers
  }

  async function createGroup(group: Group, members: GroupMember[]): Promise<[Group, GroupMember[]]> {
    if (!group.groupId) {
      if (!user.value) throw new Error('No user logged in')
      const newGroup = await postGroup(group)
      const newGroupMembers = await updateGroupMembers(newGroup, members)
      groups.value.push(newGroup)
      return [newGroup, newGroupMembers]
    }
    isLoading.value = true
    try {
      const [newGroup, groupMembers] = await Promise.all([
        updateGroup(group),
        updateGroupMembers(group, members),
      ])

      const index = groups.value.findIndex(({ groupId }) => groupId === newGroup.groupId)
      if (index > -1) {
        groups.value.splice(index, 1, newGroup)
      } else {
        groups.value.push(newGroup)
      }

      return [newGroup, groupMembers]
    } finally {
      isLoading.value = false
    }
  }

  async function removeGroup(group: Group){
    try {
      isLoading.value = true
      await deleteGroup(group.groupId)
      groups.value = groups.value.filter((g) => g.groupId !== group.groupId)
    } finally {
      isLoading.value = false
    }
  }

  async function showGroupDialog(group: Group): Promise<[SubjectCode | null, GradeCode | null]> {
    if (!group.subjects.length || !group.grade) {
      const { showDialog } = useDialogStore()
      group = await showDialog<Group>(
        defineAsyncComponent(() => import('~/components/group/UpdateGroupMissingSubjectOrGradeDialog.vue')),
        { group: group },
      )
      await updateGroup(group)
    }

    if (!group.subjects.length) throw new Error(t('planner.createYearPlan.missingSubject'))
    if (!group.grade) throw new Error(t('planner.createYearPlan.missingGrade'))

    await setUserActiveGroup(group)
    return [ group.subjects[0], group.grade ]
  }

  const activeGroupStudents = computed((): GroupMember[] => {
    if (!activeUserGroups.value.length) return []
    return activeUserGroups.value.flatMap((group: Group) => groupMembers.value[group.groupId] ?? [])
      .filter((member) => member.role === UserRole.Student)
  })

  const activeGroupStudentIds = computed((): string[] =>
    activeGroupStudents.value.map((student) => student.userId)
  )

  const sortedGroups = computed(() => {
    return [
      ...groups.value.filter(({ groupSource }) => groupSource === GroupSource.Feide).sort(sortGroups),
      ...groups.value.filter(({ groupSource }) => groupSource !== GroupSource.Feide).sort(sortGroups),
    ]
  })

  const mapGroupMember = (user: User): GroupMember => ({
    userId: user.username,
    firstName: user.userData.firstName,
    lastName: user.userData.lastName,
    fullName: user.userData.fullName,
    role: user.userData.role,
    groupRole: user.userData.role === 'teacher' ? GroupRole.Admin : GroupRole.Member,
  })

  return {
    loadGroups,
    loadGroupMembers,
    findGroupById,
    findGroupMembers,
    groups,
    basisGroups,
    teachingGroups,
    otherGroups,
    isLoading,
    activeGroupStudents,
    activeGroupStudentIds,
    groupMembers,
    hasGroups,
    hasGroupMembers,
    isAdminInGroup,
    createGroup,
    removeGroup,
    sortGroups,
    sortMembers,
    showGroupDialog,
    sortedGroups,
    updateGroup,
    mapGroupMember,
    hasStudents,
  }
})

export default useGroupsStore
