<script setup lang="ts">
import type { AggregationTermEntry, SearchResult } from '~/models/Content/Response'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsInput, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import useAggregations from '~/composables/useSearchAggregations.ts'
import useAggregationMapper from '~/composables/useAggregationMapper'
import SearchGroupedFilterBox from '~/components/search/SearchGroupedFilterBox.vue'
import SearchFilterBox from '~/components/search/SearchFilterBox.vue'
import AggregationButton from '~/components/search/AggregationButton.vue'

const props = defineProps<{
  results: SearchResult
  isLoading: boolean
  isRefetching: boolean
  criterions: Record<string, string[]>
}>()

const emit = defineEmits(['toggleCriterion'])

const resultsRef = ref(props.results)

const { t } = useI18n()
const {
  sortedGradeAggregations,
  languageAggregations,
  themeAggregations,
  genreAggregations,
  seriesAggregations,
  hasAudioAggregations,
  hasMoreAggregations,
} = useAggregations(resultsRef)
const { mapAgeGroupAggregations } = useAggregationMapper()

const audioAggregation = computed(() => hasAudioAggregations.value.find((entry) => entry.key === true))
const moreAggregation = computed(() => hasMoreAggregations.value.find((entry) => entry.key === true))

const groupGradeAggregations = computed(() => mapAgeGroupAggregations(sortedGradeAggregations.value))

const groupedGradeAggregations = computed(() => {
  return Array.from(groupGradeAggregations.value.entries()).map(([group, entries]) => ({
    group,
    key: entries.flatMap(entry => entry.key),
  }))
})

watch(() => props.results, (newResults) => {
  resultsRef.value = newResults
})
</script>

<template>
  <aside class="grid-in-filters">
    <div
      v-if="!isLoading"
      class="flex flex-col gap-4"
      :style="{'--ks-roundness': .2}"
    >
      <SearchGroupedFilterBox
        v-if="groupedGradeAggregations.length"
        :title="t('search.filterBoxes.target_group')"
        :is-refetching="isRefetching"
        :aggregations="groupedGradeAggregations"
        :criterions="criterions['grade'] ?? []"
        :translator="(entry: AggregationTermEntry) => entry.group"
        open-on-mount
        @toggle-criterion="(entry) => emit('toggleCriterion', 'grade', entry)"
      />
      <div
        v-if="audioAggregation"
        class="rounded bg-white"
      >
        <AggregationButton
          :entry="audioAggregation"
          :name="t('search.checkboxes.has_audio')"
          type="label"
          :disabled="isRefetching"
          stretch
          class="!px-[2.07rem]"
        >
          <KsInput
            id="has_audio"
            type="checkbox"
            class="shrink-0"
            :checked="criterions['checkbox']?.includes('has_audio')"
            :disabled="isRefetching"
            @click="emit('toggleCriterion', 'checkbox', 'has_audio')"
          />
        </AggregationButton>
      </div>
      <div
        v-if="moreAggregation"
        class="rounded bg-white"
      >
        <AggregationButton
          :entry="moreAggregation"
          :name="t('search.checkboxes.has_more')"
          type="label"
          :disabled="isRefetching"
          stretch
          class="!px-[2.07rem]"
        >
          <KsInput
            id="has_additions"
            type="checkbox"
            class="shrink-0"
            :checked="criterions['checkbox']?.includes('has_more')"
            :disabled="isRefetching"
            @click="emit('toggleCriterion', 'checkbox', 'has_more')"
          />
        </AggregationButton>
      </div>
      <SearchFilterBox
        v-if="themeAggregations.length"
        :title="t('search.filterBoxes.theme')"
        :is-refetching="isRefetching"
        :aggregations="themeAggregations"
        :criterions="criterions['theme'] ?? []"
        :translator="(entry: AggregationTermEntry) => entry.key"
        open-on-mount
        @toggle-criterion="(entry) => emit('toggleCriterion', 'theme', entry)"
      />
      <SearchFilterBox
        v-if="genreAggregations.length"
        :title="t('search.filterBoxes.genre')"
        :is-refetching="isRefetching"
        :aggregations="genreAggregations"
        :criterions="criterions['genre'] ?? []"
        :translator="(entry: AggregationTermEntry) => entry.key"
        @toggle-criterion="(entry) => emit('toggleCriterion', 'genre', entry)"
      />
      <SearchFilterBox
        v-if="seriesAggregations.length"
        :title="t('search.filterBoxes.series')"
        :is-refetching="isRefetching"
        :aggregations="seriesAggregations"
        :criterions="criterions['series'] ?? []"
        :translator="(entry: AggregationTermEntry) => entry.key"
        @toggle-criterion="(entry) => emit('toggleCriterion', 'series', entry)"
      />
      <SearchFilterBox
        v-if="languageAggregations.length"
        :title="t('search.filterBoxes.language')"
        :is-refetching="isRefetching"
        :aggregations="languageAggregations"
        :criterions="criterions['language'] ?? []"
        :translator="(entry: AggregationTermEntry) => entry.key"
        @toggle-criterion="(entry) => emit('toggleCriterion', 'language', entry)"
      />
    </div>
    <KsSkeletonWrapper
      v-else
      class="flex flex-col gap-4"
    >
      <KsSkeleton
        v-for="i in 6"
        :key="i"
        height="45px"
        width="100%"
        border-radius="4px"
      />
    </KsSkeletonWrapper>
  </aside>
</template>
